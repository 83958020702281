let apiUrl;

if (process.env.NODE_ENV === 'development') {
  apiUrl = 'https://localhost:7162';
} else {
  apiUrl = 'https://mortviksskogen.nu';
}

module.exports = {
  apiUrl: apiUrl
};
