import APIEnv from '../Components/APIEnv';
import axios from 'axios';
import { useEffect } from 'react';

export default function Home() {

  const memberClick = async () => {
    const memberName = document.getElementById("memberName");
    const memberEmail = document.getElementById("memberEmail");
    if(!memberName.value || !memberEmail.value){
      alert("Vänligen fyll i både namn och Email.")
    }  else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(memberEmail.value)) {
      alert("Vänligen ange en giltig e-postadress.");
  }else {
        try{
          await axios.post(`${APIEnv.apiUrl}/api/newuser`, {
            name: memberName.value,
            email: memberEmail.value,
            type: "member"
          });
          alert("Du är nu medlem!")
        } catch(error){
          if (error.response && error.response.status === 409) {
            alert("Användaren är redan registrerad.");
            console.log(error)
          
        }
      }
    }
  }
  const nameListClick = async () => {
    const nameListName = document.getElementById("nameListName");
    const nameListEmail = document.getElementById("nameListEmail");
    if(!nameListName.value || !nameListEmail.value){
      alert("Vänligen fyll i både namn och Email.")
    }  else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(nameListEmail.value)) {
      alert("Vänligen ange en giltig e-postadress.");
  }else {
      try{
        await axios.post(`${APIEnv.apiUrl}/api/newuser`, {
          name: nameListName.value,
          email: nameListEmail.value,
          type: "namelist"
        });
        alert("Du är nu uppskriven på namnlistan!")
      } catch(error){
        if (error.response && error.response.status === 409) {
          alert("Användaren är redan registrerad.");
          console.log(error)
        
      }
    }
  }
}

useEffect(() => {
  const fetchNumber = async () => {
    try {
      await axios.get(`${APIEnv.apiUrl}/api/numberofusers`).then(res => {
        document.getElementById("numberOfUsers").innerHTML = res.data;
      })
    } catch(e) {
      console.log(e)
    }
  }
  fetchNumber();
}, [])


 
    return (
      <>
        
        <div className='imagecontainer'>
        
          <img src="/images/skog 1.jpeg" />
        </div>
        
        <div className='mainContainer'>

        <div className='col-xl-8 text-center textcontainer'>
        <h1>Vår kära Mörtviksskog är hotad!</h1>
<p>Västerviks kommuns planer på att omvandla skogen vid Rödsle-rondellen är en betydande förändring som kan påverka Västerviks naturmiljö och den flora som har funnits här i århundraden.</p>
<p>Vi anser att det är viktigt att föra en dialog om dessa planer och deras långsiktiga konsekvenser.</p>
<p>Därför uppmanar vi er att engagera er i detta viktiga ämne. Ni kan stödja vårt arbete genom att bli medlem i föreningen Skydda Mörtviksskogen och genom att skriva på vår namninsamling. Tillsammans kan vi arbeta för att bevara vår värdefulla natur.</p>
{/* <p>Antal medlemmar: <div id="numberOfUsers"></div></p> */}
<p>Mer information kommer publiceras.</p>
<p>info@mortviksskogen.nu</p>
        </div>

        <div className='formContainer row d-flex justify-content-around'>

{/*           <div className='applyContainer col-lg-5'>
            <p>Bli medlem</p>
            <input id="memberName" type="text" placeholder='Namn' className='form-control' />
            <input id="memberEmail" type="text" placeholder='Email' className='mt-4 form-control'/><br></br>
            <button onClick={memberClick} className='memberButton mt-4'>Bli medlem</button>
          </div>

          <div className='signupcontainer mt-lg-0 mt-4 col-lg-5'>
          <p>Skriv på namnlista</p>
            <input id="nameListName" type="text" placeholder='Namn' className='form-control' />
            <input id="nameListEmail" type="text" placeholder='Email' className='mt-4 form-control'/><br></br>
            <button onClick={nameListClick} className='memberButton mt-4'>Skriv på</button>
          </div> */}
        </div>
        </div>
      </>
    );
}
